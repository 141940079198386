import Vue from 'vue'
import App from './App.vue'

import router from '@/middleware/router/router';
import store from '@/middleware/store/store';
import i18n from '@/middleware/i18n/i18n';
import Meta from 'vue-meta';
import Oruga from '@oruga-ui/oruga';

// Styles
require('@/assets/styles/style.scss');
require('@/assets/vendors/fontawesome/css/all.min.css');

Vue.config.productionTip = false;

Vue.use(Meta, {
    ssrAppId: 1 // https://vue-meta.nuxtjs.org/guide/caveats.html#duplicated-tags-after-hydration-with-ssr
});
Vue.use(Oruga, {
    iconPack: 'fas'
});

export const bus = new Vue();

new Vue({
    render: h => h(App),
    mounted: function() {
        // Dispatch an event after the App mounted for prerendering
        setTimeout(() => {
            document.dispatchEvent(new Event('prerender-event'));
        }, 5000);
    },
    router,
    store,
    i18n
}).$mount('#app')

// Uncomment this for debugging in production
//Vue.config.devtools = true;
