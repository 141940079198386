<template>
    <footer class="footer page-footer shadow has-background-grey-darker">
        <!-- Footer -->
        <div class="columns is-centered pt-3">
            <div class="column is-one-fourth is-flex is-flex-direction-column is-align-items-center mx-5">
                <a :href="`${getBaseUrl}${$i18n.locale}/`">
                    <img src="../../assets/logo-seenjab.png" alt="logo" />
                </a>
                <p class="is-size-4 is-size-5-mobile mt-3">
                    {{ $t("message.by") }} <strong><a href="https://www.alba-it.ch" rel="noreferrer" target="_blank">Alba IT</a></strong> © {{ getCurrentYear }}.
                </p>
            </div>
            <div class="column is-one-fourth is-flex is-flex-direction-column is-align-items-center has-text-left mx-5">
                <h3 class="title nl-title has-text-white has-text-weight-light is-size-3 is-size-4-mobile">Menu</h3>
                <div class="is-flex is-flex-direction-row">
                    <ul class="is-size-6 is-size-7-mobile">
                        <li>
                            <span class="icon">
                                <i class="fas fa-angle-right"></i>
                            </span>
                            <a class="has-text-white has-text-weight-semibold" :href="`${getBaseUrl}${$i18n.locale}`">{{ $t("navigation.home") }}</a>
                        </li>
                        <li>
                            <span class="icon">
                                <i class="fas fa-angle-right"></i>
                            </span>
                            <a class="has-text-white has-text-weight-semibold" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.news_url')}`">{{ $t("navigation.news") }}</a>
                        </li>
                        <li>
                            <span class="icon">
                                <i class="fas fa-angle-right"></i>
                            </span>
                            <a class="has-text-white has-text-weight-semibold" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.features_url')}`">{{ $t("navigation.features") }}</a>
                        </li>
                        <li>
                            <span class="icon">
                                <i class="fas fa-angle-right"></i>
                            </span>
                            <a class="has-text-white has-text-weight-semibold" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.prices_url')}`">{{ $t("navigation.pricing") }}</a>
                        </li>
                        <li>
                            <span class="icon">
                                <i class="fas fa-angle-right"></i>
                            </span>
                            <a class="has-text-white has-text-weight-semibold" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.about_url')}`">{{ $t("navigation.about") }}</a>
                            <ul class="ml-5">
                                <li>
                                    <span class="icon">
                                        <i class="fas fa-angle-right"></i>
                                    </span>
                                    <a class="has-text-white has-text-weight-semibold" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.contact_url')}`">{{ $t("navigation.contact") }}</a>
                                </li>
                                <li>
                                    <span class="icon">
                                        <i class="fas fa-angle-right"></i>
                                    </span>
                                    <a class="has-text-white has-text-weight-semibold" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.help_url')}`">{{ $t("navigation.help") }}</a>
                                </li>
                                <li>
                                    <span class="icon">
                                        <i class="fas fa-angle-right"></i>
                                    </span>
                                    <a class="has-text-white has-text-weight-semibold" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.press_url')}`">{{ $t("navigation.press") }}</a>
                                </li>
                                <li>
                                    <span class="icon">
                                        <i class="fas fa-angle-right"></i>
                                    </span>
                                    <a class="has-text-white has-text-weight-semibold" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.privacy_url')}`">{{ $t("navigation.privacy") }}</a>
                                </li>
                                <li>
                                    <span class="icon">
                                        <i class="fas fa-angle-right"></i>
                                    </span>
                                    <a class="has-text-white has-text-weight-semibold" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.terms_url')}`">{{ $t("navigation.terms") }}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="column is-one-fourth is-flex is-flex-direction-column is-align-items-center has-text-left mx-5">
                <h3 class="title nl-title has-text-white has-text-weight-light is-size-3 is-size-4-mobile">{{ $t("message.newsletter.messages.title") }}</h3>
                <sendinblue-subscribe class="nl-form" name="nlFooter" />
            </div>
            <div class="column is-one-fourth is-flex is-flex-direction-column is-align-items-center mx-5">
                <div class="buttons">
                    <form-select name="locale"
                        class="mb-2"
                        :readOnly="false"
                        :options="locales"
                        icon="globe"
                        @input="(value) => {switchLocale(value)}"
                        :value="$i18n.locale"
                        color="#FFFFFF"
                        bgColor="#494949"
                        iconColor="#FFFFFF" />
                </div>
                <div class="buttons">
                    <form-button type="button" @click="openDashboard()"
                        cssClass="has-text-white has-background-secondary is-rounded"
                        :caption="$t('navigation.login')" />
                </div>
                <div class="buttons">
                    <form-button type="button" @click="openSignup()"
                        cssClass="has-text-white has-background-primary is-rounded"
                        :caption="$t('navigation.signup')" />
                </div>
            </div>
        </div>

        <!-- Cookie consent banner -->
        <!--<cookie-law :buttonText="$t('navigation.cookie_law_accept')" theme="seenjab">
            <div id="cookie-law" slot-scope="props">
                <nav class="level">
                    <div class="level-left">
                        <p>
                            {{ $t("message.cookie_law_message") }}
                        </p>
                    </div>
                    <div class="level-right is-justify-content-flex-end">
                        <form-button type="button"
                            cssClass="has-text-white has-background-secondary is-rounded mx-1"
                            :caption="$t('navigation.cookie_law_accept')"
                            @click="props.accept" />
                    </div>
                </nav>
            </div>
        </cookie-law>-->
    </footer>
</template>

<script>
import Config from '@/config';

import { bus } from '@/main';

//import CookieLaw from 'vue-cookie-law'

import FormButton from '@/components/layout/controls/form/FormButton.vue';
import FormSelect from '@/components/layout/controls/form/FormSelect.vue';

import SendinblueSubscribe from '@/components/ext/SendinblueSubscribe.vue';

export default {
    name: 'PageFooter',
    components: {
        //CookieLaw,
        FormButton,
        FormSelect,
        SendinblueSubscribe
    },
    data: function() {
        return {
            locales: {
                en: 'English',
                fr: 'Français',
                de: 'Deutsch'
            }
        };
    },
	computed: {
        getBaseUrl: function() {
            return process.env.BASE_URL;
        },
		/**
		 * Returns the current year to show in the footer
		 * @returns {string}
		 */
		getCurrentYear: function() {
			return new Date().getFullYear();
		}
	},
    methods: {
        /**
         * Change the locale
         * @param {string} locale the new locale
         * @returns {void}
         */
        switchLocale(locale) {
			this.$store.commit('user/setLocale', locale);
            this.$i18n.locale = locale;
            bus.$emit('locale-changed', '');
        },
        openDashboard: function() {
            window.open(Config.dashboard, '_blank');
        },
        openSignup: function() {
            window.open(`${Config.dashboard}/register`, '_blank');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

.shadow {
    box-shadow: 0 -0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}

.buttons button {
    width: 150px;
}
::v-deep select {
    width: 150px !important;
}

@media(min-width: $tablet-portrait) {
    .nl-form {
        padding-top: 10px;
    }
}
</style>