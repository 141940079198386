/**
 * Router middleware
 * @author Alba IT
 * @version 1.0.0
 */
import Vue from 'vue';
import VueRouter from "vue-router";

Vue.use(VueRouter);

import store from '@/middleware/store/store';

// Routes definition
const routes = [
    {
        path: '/',
        redirect: (store.getters['user/getLocale'] !== '') ? `/${store.getters['user/getLocale']}` : `/fr/`
    },
    {
        path: '/:lang',
        component: {
            render: (h) => h('router-view')
        },
        children: [
            {
                path: "/fr",
                component: () => import('@/views/public/Home.vue')
            },
            {
                path: "/fr/news",
                component: () => import('@/views/public/News.vue')
            },
            {
                path: "/fr/fonctionnalites",
                component: () => import('@/views/public/Features.vue')
            },
            {
                path: "/fr/tarifs",
                component: () => import('@/views/public/Pricing.vue')
            },
            {
                path: "/fr/a-propos",
                component: () => import('@/views/public/About.vue')
            },
            {
                path: "/fr/contact",
                component: () => import('@/views/public/Contact.vue')
            },
            {
                path: "/fr/aide",
                component: () => import('@/views/public/Help.vue')
            },
            {
                path: "/fr/presse",
                component: () => import('@/views/public/Press.vue')
            },
            {
                path: "/fr/regles-de-confidentialite",
                component: () => import('@/views/public/Privacy.vue')
            },
            {
                path: "/fr/conditions-dutilisation",
                component: () => import('@/views/public/Terms.vue')
            },
            {
                path: "/fr/article/:slug",
                component: () => import('@/views/public/Article.vue')
            },
            {
                path: "/en",
                component: () => import('@/views/public/Home.vue')
            },
            {
                path: "/en/news",
                component: () => import('@/views/public/News.vue')
            },
            {
                path: "/en/features",
                component: () => import('@/views/public/Features.vue')
            },
            {
                path: "/en/pricing",
                component: () => import('@/views/public/Pricing.vue')
            },
            {
                path: "/en/about",
                component: () => import('@/views/public/About.vue')
            },
            {
                path: "/en/contact",
                component: () => import('@/views/public/Contact.vue')
            },
            {
                path: "/en/help",
                component: () => import('@/views/public/Help.vue')
            },
            {
                path: "/en/press",
                component: () => import('@/views/public/Press.vue')
            },
            {
                path: "/en/privacy-policy",
                component: () => import('@/views/public/Privacy.vue')
            },
            {
                path: "/en/terms-of-use",
                component: () => import('@/views/public/Terms.vue')
            },
            {
                path: "/en/article/:slug",
                component: () => import('@/views/public/Article.vue')
            },
            {
                path: "/de",
                component: () => import('@/views/public/Home.vue')
            },
            {
                path: "/de/nachrichten",
                component: () => import('@/views/public/News.vue')
            },
            {
                path: "/de/merkmale",
                component: () => import('@/views/public/Features.vue')
            },
            {
                path: "/de/preise",
                component: () => import('@/views/public/Pricing.vue')
            },
            {
                path: "/de/uber",
                component: () => import('@/views/public/About.vue')
            },
            {
                path: "/de/kontakt",
                component: () => import('@/views/public/Contact.vue')
            },
            {
                path: "/de/hilfe",
                component: () => import('@/views/public/Help.vue')
            },
            {
                path: "/de/presse",
                component: () => import('@/views/public/Press.vue')
            },
            {
                path: "/de/datenschutzpolitik",
                component: () => import('@/views/public/Privacy.vue')
            },
            {
                path: "/de/benutzungsbedingungen",
                component: () => import('@/views/public/Terms.vue')
            },
            {
                path: "/de/artikel/:slug",
                component: () => import('@/views/public/Article.vue')
            }
        ]
    },
    // 404 fallback
    {
        path: '/:pathMatch(.*)',
        component: () => import('@/views/public/NotFound.vue')
    }
];

// Get the base url according to the user locale
const path = window.location.pathname;
if (path !== '/') {
    const splitPath = path.split('/');
    const locale = splitPath[1];
    store.commit('user/setLocale', locale);
}

// Instanciate the router with the defined routes and base_url locale prefix
const router = new VueRouter({
    mode: 'history',
    routes: routes,
    base: process.env.VUE_APP_PUBLIC_PATH
});

// Hook for routes preprocessing
/*router.beforeEach((to, from, next) => {
    next();
});*/

export default router;
