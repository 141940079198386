<template>
    <div>
        <!--<h3 class="title nl-title has-text-white has-text-weight-light is-size-2 is-size-3-mobile">Newsletter</h3>-->
        <div v-if="!subscribed">
            <form-input id="email-field" type="text" name="email"
                ref="email"
                format="email"
                :placeholder="$t('message.contact.email')"
                icon="envelope"
                iconColor="#363636"
                :mandatory="true"
                @input="(value) => {email = value}"
                :value="email"
                :maxlength="64"
                :errors="errors.email" />
            <div class="nl-button">
                <form-button type="button" @click="onSubmit()"
                    cssClass="has-text-white has-background-secondary is-rounded"
                    :caption="$t('message.newsletter.subscription')" />
            </div>
            <h-captcha
                :ref="`${name}Captcha`"
                :sitekey="captchaToken"
                size="invisible"
                @verify="onVerify"
                @expired="onExpired"
                @error="onError"
                @challengeExpired="onExpired"></h-captcha>

        </div>
        <div v-if="subscribed">
            <span>{{ $t("message.newsletter.messages.success") }}</span>
        </div>
    </div>
</template>

<script>
//import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

import Config from '@/config';

import { subscribe } from '@/scripts/newsletters';

import FormInput from '@/components/layout/controls/form/FormInput.vue';
import FormButton from '@/components/layout/controls/form/FormButton.vue';
import HCaptcha from '@/components/ext/hcaptcha/HCaptcha.vue';

export default {
    name: 'SendinblueSubscribe',
    components: {
        FormInput,
        FormButton,
        //VueHcaptcha
        HCaptcha
    },
    props: {
        /**
         * Required for unique $ref generation
         */
        name: {
            type: String,
            required: true
        }
    },
    data: function() {
        this.fields = ['email'];
        return {
            email: '',
            errors: {
                email: []
            },
            subscribed: false,
            captchaToken: Config.captcha.token,
            verified: false,
            expired: false,
            token: null,
            eKey: null,
            error: null
        };
    },
    methods: {
        /**
         * Validate the field data and generate the validation errors
         * @returns {Promise}
         */
        validate: function() {
            return new Promise((resolve, reject) => {
                for (let i = 0; i < this.fields.length; i++) {
                    const field = this.$refs[this.fields[i]];

                    field.validate();
                    if (!field.valid) {
                        return reject(false);
                    }
                }
                return resolve(true);
            });
        },
        /**
         * Send a subscription request to Sendinblue
         * @returns {void}
         */
        onSubmit: function() {
            // Validate the form
            this.validate()
            .then(() => {
                // If the validation is sucessful, init hCaptcha
                this.$refs[`${this.name}Captcha`].execute();
            })
            .catch(() => console.log("Validation failed."));
        },
        /**
         * On hCaptcha challenge verification
         * @returns {void}
         */
        onVerify: function(token, ekey) {
            this.verified = true;
            this.token = token;
            this.eKey = ekey;
            //console.log(`Callback token: ${token}, ekey: ${ekey}`);

            // Send the subscription
            const list = Config.newsletters.lists[this.$i18n.locale];
            subscribe(this.email, list)
            .then(() => {
                this.subscribed = true;
            })
            .catch((err) => console.log(err));
        },
        /**
         * On hCaptcha challenge expiration
         * @returns {void}
         */
        onExpired: function() {
            this.verified = false;
            this.token = null;
            this.eKey = null;
            this.expired = true;
            //console.log('Expired');
        },
        /**
         * On hCaptcha challenge error
         */
        onError: function(err) {
            this.token = null;
            this.eKey = null;
            this.error = err;
            //console.log(`Error: ${err}`);
        }
    }
}
</script>

<style lang="scss" scoped>
#email-field {
    max-width: 300px;
}
/*@media screen and (max-width: 768px) {
    .nl-button {
        text-align: center;
    }
}*/
button {
    width: 150px;
}
</style>