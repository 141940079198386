/**
 * Data stores middleware
 * @author Alba IT
 * @version 0.1.0
 */
 import Vue from 'vue';
 import Vuex from 'vuex';
 import createPersistedState from 'vuex-persistedstate';
 
 import user from '@/middleware/store/stores/user';

 Vue.use(Vuex);
 
 const store = new Vuex.Store({
     // Data stores modules
     modules: {
         user
     },
     // Add the PersistedState plugin and also add a logger when in dev mode
     plugins: (process.env.NODE_ENV !== 'production') ?
              [createPersistedState()] :
              [createPersistedState()],
     // Enable strict mode on production
     strict: process.env.NODE_ENV !== 'production'
 });
 
 export default store;
 