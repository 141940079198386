<template>
    <nav class="navbar is-secondary is-transparent mt-3" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <a class="navbar-item ml-4" id="logo" :href="`${getBaseUrl}${$i18n.locale}/`">
                <img src="../../assets/logo-seenjab.png" alt="logo" />
            </a>

            <a role="button" class="navbar-burger has-text-white has-text-weight-bold" :class="{'is-active': menuOpen}" @click="toggleMenu()" aria-label="menu" aria-expanded="false" data-target="navbar">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="navbar" class="navbar-menu" :class="{'is-active': menuOpen}">
            <div class="navbar-start has-text-left">
                <a class="navbar-item has-text-white has-text-weight-semibold is-size-5-desktop is-size-6-mobile mx-1" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.news_url')}`">{{ $t("navigation.news") }}</a>
                <a class="navbar-item has-text-white has-text-weight-semibold is-size-5-desktop is-size-6-mobile mx-1" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.features_url')}`">{{ $t("navigation.features") }}</a>
                <a class="navbar-item has-text-white has-text-weight-semibold is-size-5-desktop is-size-6-mobile mx-1" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.prices_url')}`">{{ $t("navigation.pricing") }}</a>
                
                <div class="navbar-item has-dropdown is-hoverable">
                    <a class="navbar-link has-text-white has-text-weight-semibold is-size-5-desktop is-size-6-mobile mx-1"
                        :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.about_url')}`">
                        {{ $t("navigation.about") }}
                    </a>

                    <div class="navbar-dropdown has-background-primary">
                        <!--<a class="navbar-item has-text-white has-text-weight-semibold is-size-5-desktop is-size-6-mobile mx-2" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.about_url')}`">{{ $t("navigation.about") }}</a>-->
                        <a class="navbar-item has-text-white has-text-weight-semibold is-size-5-desktop is-size-6-mobile mx-1" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.contact_url')}`">{{ $t("navigation.contact") }}</a>
                        <a class="navbar-item has-text-white has-text-weight-semibold is-size-5-desktop is-size-6-mobile mx-1" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.help_url')}`">{{ $t("navigation.help") }}</a>
                        <a class="navbar-item has-text-white has-text-weight-semibold is-size-5-desktop is-size-6-mobile mx-1" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.press_url')}`">{{ $t("navigation.press") }}</a>
                        <a class="navbar-item has-text-white has-text-weight-semibold is-size-5-desktop is-size-6-mobile mx-1" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.privacy_url')}`">{{ $t("navigation.privacy") }}</a>
                        <a class="navbar-item has-text-white has-text-weight-semibold is-size-5-desktop is-size-6-mobile mx-1" :href="`${getBaseUrl}${$i18n.locale}/${$t('navigation.terms_url')}`">{{ $t("navigation.terms") }}</a>
                    </div>
                </div>
            </div>

            <div class="navbar-end has-text-centered">
                <div class="navbar-item mx-0 px-1">
                    <div class="buttons is-centered">
                        <form-button type="button" @click="openDashboard()"
                            cssClass="has-text-white has-background-secondary is-rounded"
                            :caption="$t('navigation.login')" />
                    </div>
                </div>
                <div class="navbar-item mx-0">
                    <div class="buttons is-centered">
                        <form-button type="button" @click="openSignup()"
                            cssClass="has-text-white has-background-primary is-rounded"
                            :caption="$t('navigation.signup')" />
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
/**
 * NavBar Component
 * The top navigation menu
 * @author Alba IT
 * @version 1.0.0
 */
import Config from '@/config';

import FormButton from '@/components/layout/controls/form/FormButton.vue';

/**
 * States declaration
 */
export default {
    name: 'NavBar',
    components: {
        FormButton,
    },
    data: function() {
        return {
            menuOpen: false
        };
    },
    computed: {
        getBaseUrl: function() {
            return process.env.BASE_URL;
        }
    },
    emits: ['toggle'],
    methods: {
        /**
         * Toggle the burger menu on and off (mobile)
         * @returns {void}
         */
        toggleMenu: function() {
            this.menuOpen = !this.menuOpen;
            this.$emit('toggle', this.menuOpen);
        },
        openDashboard: function() {
            window.open(Config.dashboard, '_blank');
        },
        openSignup: function() {
            window.open(`${Config.dashboard}/register`, '_blank');
        }
    }
}
</script>
