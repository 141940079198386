import Config from '@/config';

/**
 * Add an user to the Sendinblue contacts
 * @param {string} email the contact email
 * @param {string} listId the Sendinblue mailing list ID
 * @returns {Promise}
 */
export function subscribe(email, listId) {
    return fetch(`${Config.newsletters.url}/v3/contacts/doubleOptinConfirmation`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'api-key': Config.newsletters.token
        },
        body: JSON.stringify({
            email: email,
            includeListIds: [listId],
            templateId: Config.newsletters.doiTemplate,
            redirectionUrl: Config.url
        })
    })
    .then((response) => {
        if (response.ok) {
            return Promise.resolve('ok');
        } else {
            return Promise.reject(response.statusText);
        }
    })
    //.then((result) => result.json())
    //.then((result) => result.data);
}
