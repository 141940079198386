const Config = {
    url: "https://seenjab.com",
    dashboard: "https://dashboard.seenjab.com",
    meta: {
        lastmod: "2021-08-06"
    },
    /**
     * smtpjs configuration
     */
    email: {
        token: "10937170cf106c40d7c79bc2d76b3e943aba6642b8210b37f7ba3c4a5aaf2409"
    },
    /**
     * hCaptcha configuration
     */
    captcha: {
        token: (process.env.NODE_ENV !== 'production') ? "10000000-ffff-ffff-ffff-000000000001" : "c69154c3-f76d-4a32-9a03-007aa18147d5"
    },
    /**
     * Directus Articles backend configuration
     */
    articles: {
        url: 'https://headless.seenjab.com',            // The Directus instance URL
        token: '03b50b1c-dd1f-443d-bd07-963e59a92e0b'   // The readonly account API access token
    },
    /**
     * Sendinblue Newsletter configuration
     */
    newsletters: {
        url: "https://api.sendinblue.com",
        token: "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",
        lists: {
            en: 33,
            fr: 31,
            de: 34
        },
        doiTemplate: 94
    }
};

export default Config;