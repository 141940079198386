<template>
    <button class="button" :class="cssClass" :type="type" @click="$emit('click')">
        <span class="icon is-small" v-if="icon !== ''">
            <i :class="getIcon"></i>
        </span>
        <span class="has-text-weight-semibold">{{ caption }}</span>
    </button>
</template>

<script>
/**
 * FormButton Component
 * Displays a button on the page and emits a click event
 * @author Alba IT
 * @version 1.0.0
 */
export default {
    name: 'FormButton',
    props: {
        /**
         * The button type
         */
        type: {
            type: String,
            default: 'button'
        },
        /**
         * The button caption
         */
        caption: {
            type: String
        },
        /**
         * The button icon
         */
        icon: {
            type: String,
            default: ''
        },
        /**
         * The CSS extra classes
         */
        cssClass: {
            type: String,
            default: ''
        }
    },
    emits: ['click'],
    computed: {
        /**
         * Get the FontAwesome icon to display
         * @return {string} the FontAwesome icon name
         */
        getIcon: function() {
            return (this.icon !== '') ? 'fas fa-' + this.icon : '';
        }
    }
}
</script>
