/**
 * The user data store definition
 * @author Alba IT
 * @version 1.0.0
 */

// States definition
const state = () => ({
    user: {
        logged: false,
        username: '',
        locale: '',
        access_token: '',
        expires_in: '',
        refresh_token: '',
        refresh_expires_in: '',
        token_creation: ''
    }
});

// Mutations (sync updates) definitions
const mutations = {
    setLogged(state, logged) {
        state.user.logged = logged;
    },
    setUsername(state, username) {
        state.user.username = username;
    },
    setLocale(state, locale) {
        state.user.locale = locale;
    },
    setAccessToken(state, access_token) {
        state.user.access_token = access_token;
    },
    setExpiresIn(state, expires_in) {
        state.user.expires_in = expires_in;
    },
    setRefreshToken(state, refresh_token) {
        state.user.refresh_token = refresh_token;
    },
    setRefreshExpiresIn(state, refresh_expires_in) {
        state.user.refresh_expires_in = refresh_expires_in;
    },
    setTokenCreation(state, token_creation) {
        state.user.token_creation = token_creation;
    }
};

// Actions (async updates) definitions
const actions = {
    setLogged(context, data) {
        context.commit('setLogged', data.logged);
    },
    setUsername(context, data) {
        context.commit('setUsername', data.username);
    },
    setLocale(context, data) {
        context.commit('setLocale', data.locale);
    },
    setAccessToken(context, data) {
        context.commit('setAccessToken', data.access_token);
    },
    setExpiresIn(context, data) {
        context.commit('setExpiresIn', data.expires_in);
    },
    setRefreshToken(context, data) {
        context.commit('setRefreshToken', data.refresh_token);
    },
    setRefreshExpiresIn(context, data) {
        context.commit('setRefreshExpiresIn', data.refresh_expires_in)
    },
    setTokenCreation(context, data) {
        context.commit('setTokenCreation', data.token_creation);
    }
};

// Getters definitions
const getters = {
    isLogged(state) {
        return state.user.logged;
    },
    getUsername(state) {
        return state.user.username;
    },
    getLocale(state) {
        return state.user.locale;
    },
    getAccessToken(state) {
        return state.user.access_token;
    },
    getExpiresIn(state) {
        return state.expires_in;
    },
    getRefreshToken(state) {
        return state.user.refresh_token;
    },
    getRefreshExpiresIn(state) {
        return state.refresh_expires_in;
    },
    getTokenCreation(state) {
        return state.token_creation;
    }
};

// Export the user module data store
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
