<template>
    <span class="icon" :style="getStyle" @click="scrollToTop()" v-if="visible">
        <i :class="getIcon"></i>
    </span>
</template>

<script>
/**
 * ScrollToTop Component
 * Displays a button allowing to scroll to the top of a page
 * @author Alba IT
 * @version 1.0.0
 */
export default {
    name: 'ScrollToTop',
    props: {
        /**
         * The Font Awesome icon to display
         */
        icon: {
            type: String,
            default: 'chevron-up'
        },
        /**
         * The scrolling behavior
         */
        behavior: {
            type: String,
            default: 'smooth'
        },
        /**
         * The bottom fixed position value
         */
        bottom: {
            type: String,
            default: '48px'
        },
        /**
         * The right fixed position value
         */
        right: {
            type: String,
            default: '48px'
        },
        /**
         * The button width
         */
        width: {
            type: String,
            default: '32px'
        },
        /**
         * The button height
         */
        height: {
            type: String,
            default: '32px'
        },
        /**
         * The border width
         */
        borderWidth: {
            type: String,
            default: '3px'
        },
        /**
         * The icon color
         */
        iconColor: {
            type: String,
            default: '#000000'
        },
        /**
         * The background color
         */
        bgColor: {
            type: String,
            default: '#11ffee00'
        },
        /**
         * The border color
         */
        borderColor: {
            type: String,
            default: '#000000'
        },
        /**
         * The radius
         */
        radius: {
            type: String,
            default: '32px'
        }
    },
    data: function() {
        return {
            visible: false
        };
    },
    computed: {
        /**
         * Generate the button styling according to the component props
         * @return {string}
         */
        getStyle: function() {
            return `
                position: fixed;
                z-index: 20;
                bottom: ${this.bottom};
                right: ${this.right};
                cursor: pointer;
                width: ${this.width};
                height: ${this.height};
                text-align: center;
                border: ${this.borderWidth} solid ${this.borderColor};
                border-radius: ${this.radius};
                background-color: ${this.bgColor};
                color: ${this.iconColor};
            `;
        },
        /**
         * Get the FontAwesome icon to display
         * @return {string} the FontAwesome icon name
         */
        getIcon: function() {
            return (this.icon !== '') ? 'fas fa-' + this.icon : '';
        }
    },
    mounted: function() {
        // Scroll event implementation
        window.onscroll = () => {
            // If they pageYOffset is bigger than 10, show the button
            this.visible = (window.pageYOffset > 10) ? true : false
        }
    },
    methods: {
        /**
         * The scroll to top event implementation
         * @returns {void}
         */
        scrollToTop: function() {
            window.scrollTo({
                top: 0,
                behavior: this.behavior
            });
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
