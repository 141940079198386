<template>
	<div id="app">
		<div id="overlay">
            <div id="wrapper" v-cloak>
                <header>
                    <nav-bar />
                </header>
                <div class="container is-fluid has-text-left pt-6">
                    <router-view />
                </div>
            </div>
		</div>
		<page-footer />
        <scroll-to-top iconColor="#FFFFFF" bgColor="#442178" borderColor="#442178" />
	</div>
</template>

<script>
/**
 * Seenjab Web Root (main) component
 * @author Alba IT
 * @version 1.0.0
 */
import NavBar from '@/components/layout/NavBar.vue';
import PageFooter from '@/components/layout/PageFooter.vue';
import ScrollToTop from '@/components/layout/controls/ScrollToTop.vue';

export default {
	name: 'App',
	components: {
		NavBar,
        PageFooter,
        ScrollToTop
	},
    metaInfo: function() {
        return {
            title: `${this.$t("message.home.meta.title")} | ${this.$t('message.title')}`,
            htmlAttrs: {
                lang: (this.$store.getters['user/getLocale'] != '') ? this.$store.getters['user/getLocale'] : 'en',
                //amp: false
            },
            meta: [
                {
                    name: 'description',
                    content: this.$t('message.home.meta.description')
                },
				{
					name: 'author',
					content: this.$t('message.meta.author')
				}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
    .container {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}
</style>
