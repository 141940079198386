<template>
    <div class="field">
        <label class="label" v-if="(caption !== '')">{{ caption }}</label>
        <div class="control has-icons-left">
            <div class="select is-rounded">
                <select :style="getStyle" :value="value" @input="$emit('input', $event.target.value)">
                    <option v-for="(option, index) in options" :key="index" :value="index">{{ option }}</option>
                </select>
            </div>
            <div class="icon is-small is-left" :style="`color: ${iconColor};`">
                <i :class="getIcon"></i>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * FormSelect component
 * Wraps Bulma form select to avoid code repetition
 * @author Alba IT
 * @version 1.0.0
 */
export default {
    name: 'FormSelect',
    props: {
        /**
         * The field caption
         */
        caption: {
            type: String,
            default: ''
        },
        /**
         * The field icon
         */
        icon: {
            type: String,
            default: ''
        },
        /**
         * The icon color
         */
        iconColor: {
            type: String,
            default: '#000000'
        },
        /**
         * Is the field read only ?
         */
        readOnly: {
            type: Boolean,
            default: false
        },
        /**
         * The field options list
         */
        options: {
            type: Object,
            required: true
        },
        /**
         * The icon and text color
         */
        color: {
            type: String,
            default: '#000000'
        },
        /**
         * The background color
         */
        bgColor: {
            type: String,
            default: '#FFFFFF'
        },
        /**
         * The field currently selected option
         */
        value: {
            type: String
        }
    },
    emits: ['input'],
    computed: {
        /**
         * Get the FontAwesome icon to display
         * @return {string} the FontAwesome icon name
         */
        getIcon: function() {
            return (this.icon !== '') ? 'fas fa-' + this.icon : '';
        },
        /**
         * Generate the style attribute from the component props
         * @returns {string} the style properties
         */
        getStyle: function() {
            return `
                color: ${this.color};
                background-color: ${this.bgColor};
            `;
        }
    }
}
</script>

<style scoped>
.select, .select select{ 
    width: 100%;
}
</style>