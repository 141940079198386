/**
 * Localization middleware
 * @author Alba IT
 * @version 1.0.0
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

import store from '@/middleware/store/store';

// Import the localization french dictionaries
import * as message_fr from '@/middleware/i18n/locales/message/fr.json';
import * as navigation_fr from '@/middleware/i18n/locales/navigation/fr.json';

// Import the localization english dictionaries
import * as message_en from '@/middleware/i18n/locales/message/en.json';
import * as navigation_en from '@/middleware/i18n/locales/navigation/en.json';

// Import the localization german dictionaries
import * as message_de from '@/middleware/i18n/locales/message/de.json';
import * as navigation_de from '@/middleware/i18n/locales/navigation/de.json';

// Build the internationalization object using the external .json localization dictionaries
const messages = {
    fr: {
        message: message_fr.default,
        navigation: navigation_fr.default
    },
    en: {
        message: message_en.default,
        navigation: navigation_en.default
    },
    de: {
        message: message_de.default,
        navigation: navigation_de.default
    }
};

// Get the base url according to the user locale
let locale = 'fr';
const userLocale = store.getters['user/getLocale'];
let path = window.location.pathname;
const splitPath = path.split('/');
if (splitPath.length > process.env.BASE_URL.split('/').length) {
    locale = splitPath[1];
}
store.commit('user/setLocale', locale);

// Build the i18n instance
const i18n = new VueI18n({
    locale: (userLocale !== '') ? userLocale : locale,
    fallbackLocale: 'en',
    messages
});
i18n.locale = (userLocale !== '') ? userLocale : locale;

export default i18n;
