<template>
    <div class="field">
        <!-- Field label -->
        <div class="field-label is-normal has-text-left is-size-5 is-size-6-mobile">
            <label class="label has-text-white" v-if="(caption !== '')">{{ caption }}{{  mandatory ? '*' : '' }}</label>
        </div>
        <!-- Single line text field -->
        <div class="control is-expanded" :class="{'has-icons-left': (icon !== '')}" v-if="['text', 'password'].includes(type)">
            <input class="input"
                :style="`color:${iconColor};`"
                :class="{'is-static': readonly, 'is-error': errors.length > 0}"
                :type="type" :name="name"
                :placeholder="placeholder"
                :value="value"
                @input="$emit('input', $event.target.value)"
                @blur="validate"
                :readonly="readonly"
                :maxlength="maxlength" />
            <span class="icon is-left has-text-grey-darker" v-if="(icon !== '')">
                <i :class="getIcon"></i>
            </span>
        </div>
        <!-- Multi lines text field -->
        <div class="control is-expanded" v-if="(type === 'textarea')">
            <textarea class="textarea"
                :class="{'is-error': errors.length > 0}"
                :name="name"
                :placeholder="placeholder"
                :value="value"
                @input="$emit('input', $event.target.value)"
                @blur="validate"
                :readonly="readonly"
                :maxlength="maxlength"></textarea>
        </div>
        <!-- Validation error message -->
        <div class="field-label is-italic has-text-left is-size-6 is-size-7-mobile" v-for="(error, index) in errors" :key="index">
            <label class="label has-text-error">{{ error }}</label>
        </div>
    </div>
</template>

<script>
/**
 * FormInput Component
 * Wraps Bulma form input to avoid code repetition
 * @author Alba IT
 * @version 1.0.0
 */
export default {
    name: 'FormInput',
    props: {
        /**
         * The field type
         * @values text, password, textarea
         */
        type: {
            type: String,
            required: true,
        },
        /**
         * The field name
         */
        name: {
            type: String,
            required: true
        },
        /**
         * The field caption
         */
        caption: {
            type: String,
            default: ''
        },
        /**
         * The field placeholder
         */
        placeholder: {
            type: String,
            default: ''
        },
        /**
         * The field icon
         */
        icon: {
            type: String,
            default: ''
        },
        iconColor: {
            type: String,
            default: '#000000'
        },
        /**
         * Is the field read only ?
         */
        readonly: {
            type: Boolean,
            default: false
        },
        /**
         * Is the field mandatory ?
         */
        mandatory: {
            type: Boolean,
            default: false
        },
        /**
         * The maximum allowed length (default = 65535)
         */
        maxlength: {
            type: Number,
            default: 65535
        },
        /**
         * The field format.
         * Values : ["none", "int", "float", "date", "email", "/<regex>/"]
         */
        format: {
            type: String,
            default: "none"
        },
        /**
         * The field value
         */
        value: {
            type: String
        }
    },
    data: function() {
        return {
            valid: false,
            errors: []
        };
    },
    emits: ['input'],
    computed: {
        /**
         * Get the FontAwesome icon to display
         * @return {string} the FontAwesome icon name
         */
        getIcon: function() {
            return (this.icon !== '') ? 'fas fa-' + this.icon : '';
        }
    },
    methods: {
        /**
         * Validate the field and display the validation error if necessary
         * @returns {void}
         */
        validate: function() {
            this.valid = false;
            this.errors = [];

            // Mandatory validation
            if (this.mandatory && this.value === '') {
                // Error
                this.valid = false;
                this.errors.push(this.$t("forminput.errors.empty_field"));
            } else {
                this.valid = true;
            }

            // Format validation
            switch (this.format) {
                case "int":
                    if (!Number.isInteger(this.value)) {
                        // Error
                        this.valid = false;
                        this.errors.push(this.$t("forminput.errors.invalid_type", {type: this.$t("forminput.types.int")}));
                    } else {
                        this.valid = true;
                    }
                    break;
                case "float":
                    if (Number.isNaN(this.value)) {
                        // Error
                        this.valid = false;
                        this.errors.push(this.$t("forminput.errors.invalid_type", {type: this.$t("forminput.types.float")}));
                    } else {
                        this.valid = true;
                    }
                    break;
                case "date":
                    if (!(new Date(this.value) instanceof Date) || Number.isNaN(new Date(this.value))) {
                        // Error
                        this.valid = false;
                        this.errors.push(this.$t("forminput.errors.invalid_type", {type: this.$t("forminput.types.date")}));
                    } else {
                        this.valid = true;
                    }
                    break;
                case "email":
                    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-]+)*$/.test(this.value)) {
                        // Error
                        this.valid = false;
                        this.errors.push(this.$t("forminput.errors.invalid_email"));
                    } else {
                        this.valid = true;
                    }
                    break;
                default:
                    if (this.format !== 'none') {
                        // Regex
                        if (!this.format.test(this.value)) {
                            // Error
                            this.valid = false;
                            this.errors.push(this.$t("forminput.errors.invlid_format"));
                        } else {
                            this.valid = true;
                        }
                    } else {
                    this.valid = true;
                }
            }
        }
    },
    /**
     * Translations
     */
    i18n: {
        messages: {
            en: {
                forminput: {
                    errors: {
                        empty_field: "This field must be filled.",
                        invalid_format: "This field format is invalid.",
                        invalid_type: "This field must contain a {type} value.",
                        invalid_email: "The email address is invalid."
                    },
                    types: {
                        string: "characters string",
                        int: "integer",
                        float: "decimal number",
                        date: "date",
                        email: "email address"
                    }
                }
            },
            fr: {
                forminput: {
                    errors: {
                        empty_field: "Ce champ doit être rempli.",
                        invlid_format: "Le format de ce champ est invalide.",
                        invalid_type: "Ce champ doit contenir {type}.",
                        invalid_email: "L'adresse email est invalide."
                    },
                    types: {
                        string: "une chaîne de caractères",
                        int: "un nombre entier",
                        float: "un nombre décimal",
                        date: "une date",
                        email: "une adresse email"
                    }
                }
            },
            de: {
                forminput: {
                    errors: {
                        empty_field: "Dieses Feld muss ausgefüllt werden",
                        invlid_format: "Das Format dieses Feldes ist ungültig",
                        invalid_type: "Dieses Feld muss {type} enthalten.",
                        invalid_email: "Die E-Mail-Adresse ist ungültig."
                    },
                    types: {
                        string: "eine Zeichenkette",
                        int: "eine ganze Zahl",
                        float: "eine Dezimalzahl",
                        date: "ein Datum",
                        email: "eine E-Mail-Adresse"
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
input,
textarea {
    color: #000000;
    &::placeholder {
        color:#000000;
    }
}
.is-error {
    background-color: #f5a5a5;
    border-color: #ff1818;
}
</style>